/* eslint-disable */
import React, { PureComponent } from 'react';

import Helmet from 'react-helmet';

import seo from 'tools/seo';

import Loading from 'components/Loading';

type Props = {};

class TermsAndConditions extends PureComponent<Props> {
  state = { loaded: false };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { loaded } = this.state;
    // https://magic.reactjs.net/htmltojsx.htm
    return (
      <>
        <Helmet>
          {seo.title('Terms and conditions')}
          {seo.description(
            "How to correctly use Flowfo? All details in this page.",
          )}
        </Helmet>
        {!loaded && <Loading />}
        <div className={`Page-Webflow ${loaded || 'd-none'}`}>
          {/* DEBUT HTML WEBFLOW terms and conditions english */}

          {/* This site was created in Webflow. http://www.webflow.com */}
          {/* Last Published: Wed Apr 21 2021 01:48:26 GMT+0000 (Coordinated Universal Time) */}
          <meta charSet="utf-8" />
          <title>Terms and conditions - ENGLISH</title>
          <meta content="Terms and conditions - ENGLISH" property="og:title" />
          <meta content="Terms and conditions - ENGLISH" property="twitter:title" />
          <meta content="Webflow" name="generator" />
          <link
            href="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/css/prices-flowfo.webflow.f05491632.css"
            onLoad={this.onLoad}
            rel="stylesheet"
            type="text/css"
          />
          {/*[if lt IE 9]><![endif]*/}
          <link href="https://uploads-ssl.webflow.com/img/favicon.ico" rel="shortcut icon" type="image/x-icon" />
          <link href="https://uploads-ssl.webflow.com/img/webclip.png" rel="apple-touch-icon" />
          <div>
            <div className="divdeslangages">
              <a href="https://flowfo.me/fr/terms-and-conditions" className="linklanguage w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/5f95ec5bf00d8b10e6b4166c_THREE%20FLAGS.png"
                  alt=""
                  className="drapeauxronds"
                />
                <h2 className="changelangue">lire en français</h2>
              </a>
            </div>
            <div className="sectionessai">
              <h1 className="webflowheading1">Terms and conditions</h1>
              <h3 className="webflowheading3">Let's start</h3>
              <p className="webflowparagraphe">
                Here are the terms and conditions of the Flowfo contract.
                <br />
                “We,” “our” “us” refers to Flowfo. “Flowfo” refers to the platform and the services associated with it.{' '}
                <br />
                By using Flowfo, you declare that you agree with these conditions and the{' '}
                <a href="https://flowfo.me/prices" target="_blank" className="webflowlink">
                  Pricing
                </a>{' '}
                and{' '}
                <a href="https://flowfo.me/content-policy" target="_blank" className="webflowlink">
                  Content Policy
                </a>{' '}
                appendices.
                <br />
                In case of purchase on the site, you also agree to the conditions of our payment providers.
              </p>
              <h3 className="webflowheading3">Definitions</h3>
              <p className="webflowparagraphe">
                "Visitor" : someone who has not signed up to a Flowfo account.
                <br />
                "User" : someone who has signed up to a Flowfo account.
                <br />
                "Creator" : a user who has created one or many Artwork pages.
                <br />
                "Artwork" : an artwork is a page where the creator presents a universe, a project.
                <br />
                "Digital goods" : any files (music, images, text…) that may be stocked in a cloud based drive.
                <br />
                <strong>"Flow" : the Flow is a folder inside which creator uploads files. </strong>
                <br />
                "Flower" : a user who follows (=likes) an artwork.
                <br />
                "News" : an article written and published by the Creator.
              </p>
              <h3 className="webflowheading3">Your account</h3>
              <p className="webflowparagraphe">
                <strong>
                  You must be at least 13 years old to register and 18 years old to become a creator.
                  <br />
                </strong>
                The purchase of digital goods by a minor must be done with the consent of the parents.
                <br />
                You are responsible for the activities of your account and you agree to keep it secure.
                <br />
                Contact us immediately if you believe your account is compromised (contact@flowfo.me)
                <br />
                <br />
                Your account cannot be transferred to another person.
                <br />
                You can delete your account anytime by going to the user settings page https://flowfo.me/profile/settings.
              </p>
              <h3 className="webflowheading3 violet">Abusive conduct</h3>
              <p className="webflowparagraphe">
                If you violate the terms of this contract, we may delete your account. <br />
                Do not do anything illegal, do not try to saturate the site or corrupt its code.
                <br />
                If you are a creator raising funds on Flowfo, we can be held responsible for what you do with those
                funds. As a result, we may review what you do with your membership outside of our platform.
              </p>
              <h3 className="webflowheading3 violet">Selling digital goods</h3>
              <p className="webflowparagraphe">
                Flowfo sells digital goods stored in Flows by creators.
                <br />
                The type of Flow and its price are defined by the creator who owns the Flow.
                <br />
                <br />
                <strong>A FREE FLOW</strong> is a folder whose content is accessible to all (visitors included).
                <br />A free Flow can become a paying Flow (and vice versa).
                <br />
                <br />
                <strong>A PAYING FLOW</strong> is a folder whose content is accessible only to users who have purchased
                an access.
                <br />
                This access remains valid throughout the life of the Flow.
                <br />
                <br />
                <strong>A SUBSCRIPTION FLOW</strong> is a folder whose content is accessible only to subscribers
                (monthly renewal).
                <br />
                <br />
                <strong>Flows prices have a minimum of € 2 and a maximum of € 50 (excluding VAT).</strong>
              </p>
              <h3 className="webflowheading3 red">The subscription Flow</h3>
              <p className="webflowparagraphe">
                <strong>The subscription Flow is done on a tacitly renewable monthly basis.</strong>
                <br />
                <strong>A subscriber can end his subscription at any time.</strong> Access to the Flow remains acquired
                until the end of the current month. No discount can be granted.
                <br />
                <br />
                <strong>The subscription is always renewed on the 1st of the month. There is no sliding period.</strong>
                <br />
                As access to the Flow is instantaneous, the 1st installment is always immediately paid and is not
                subject to any discount (even if the end of the month is approaching).
                <br />
                <br />
                Subscriptions to Flows are cumulative: if a user subscribes to several Flows, he pays Flow A + Flow B +
                Flow C... <br />
                <br />
                The price of the subscription can be changed at any time by the creator.
                <br />
                This change is taken into account immediately for new subscribers and starts on the 1st of the following
                month for others. We strongly recommend that creators communicate in advance about their price change
                (via news).We are likely to block any changes that we think are suspicious.
                <br />
                <br />
                The content of the Flow can be changed at any time by the creator.
                <br />
                This means that the files can be renamed, modified or deleted.
                <br />
                As a Flow subscriber, you understand that its content fluctuates. <br />‍
                <strong>
                  The price of the subscription does not depend on the content of the Flow.
                  <br />‍
                </strong>
                <br />
                In order to avoid loss of content, we remind subscribers that they can download Flow whenever they want.
                <br />
                The Flow itself can be deleted at any time. The renewal of the subscription is then stopped.
                <br />
                <strong>
                  If the Flow is deleted, all subscribers are lost for the creator. There is no cancellation possible.
                </strong>
              </p>
              <h3 className="webflowheading3 red">The paying Flow</h3>
              <p className="webflowparagraphe">
                Access to the content of the Paiying Flow is done via a single sale.
                <br />
                If new files are placed in the Flow after the sale, they are automatically readable by the buyer
                (without having to pay again).
                <br />
                <br />
                The price can be changed at any time by the creator. It can even become free.
                <br />
                The price change does not affect the access of former buyers.
                <br />
                As a creator, you understand that the Flow is sold only once.
                <br />
                As a buyer, you understand that the Flow may have a different price (or free) after your purchase.
                <br />
                No discount can be made.
                <br />
                <br />
                The content of the Flow can be changed at any time by the creator.
                <br />
                This means that the files can be renamed, modified or deleted.
                <br />
                The Flow itself can be deleted by the creator.
                <br />
                To avoid loss of content, we remind buyers that they can download the Flow whenever they want.
              </p>
              <h3 className="webflowheading3">Payment to the creators</h3>
              <p className="webflowparagraphe">
                <strong>
                  Payments to creators (PAYOUT) are triggered on the 10th of each month (Paris time).The source currency
                  is Euro €.
                </strong>
                <br />
                No advance can be granted.
                <br />
                <br />
                The method of payment is chosen by the creator.
                <br />
                <strong>SEPA TRANSFER</strong> - for European creators with a compatible bank account.
                <br />
                <strong>PAYPAL</strong> - we need to pay creators through business transactions which may generate fees.
                Unfortunatly, the <em>Friends and family</em> option is not possible.
                <br />
                If the desired currency is different from Euro, Paypal may also charge you exchange fees. For more
                details, visit www.paypal.com
                <br />
                <br />
                We may block or suspend payments if you violate our policies or for compliance reasons, including
                collecting tax information. When payments are delayed or blocked, we try to promptly communicate the
                reason.{' '}
              </p>
              <h3 className="webflowheading3 violet">Commission</h3>
              <p className="webflowparagraphe">
                Flowfo charges a commission for each transaction made.
                <br />
                <strong>The commission is: 10.9% of the price (excluding taxes) + € 0.30</strong>
                <br />
                Any sale, subscription or renewal of subscription is considered a transaction.
                <br />
                If a user buys 3 Flows in the same order, there are 3 transactions.
                <br />
              </p>
              <h3 className="webflowheading3 violet">Payment certificate</h3>
              <p className="webflowparagraphe">
                All remuneration is subject to a payment certificate sent to the creator by email.
                <br />
                This certificate details the number of sales, prices and commissions.
                <br />
                It is sent on the 10th of each month if transactions have been made.
                <br />
                As the creator, you authorize Flowfo to edit this document on your behalf.
                <br />
                Contact us immediately at payment@flowfo.me if you do not receive this document (check your spam).
                <br />
              </p>
              <h3 className="webflowheading3 red">Tax obligations</h3>
              <p className="webflowparagraphe">
                Our model is that of e-commerce. We are not a marketplace.
                <br />
                As a creator, you sell digital goods to Flowfo which we resell to the public.
                <br />
                <br />
                <strong>You are an individual creator:</strong> your remuneration must be declared to the tax services.
                The payment certificates serve as proof. <br />
                If you have regular and large sales, you will most likely have to change status and pay VAT. Consult a
                tax expert or your tax administration to find out the thresholds.
                <br />
                <strong>You are a creator with a company:</strong> you accept that the remuneration paid by Flowfo
                includes all taxes that you may have to pay in your state or country.
                <br />
                If you are required to generate your own invoice, please send it to us by email at payment@flowfo.me
                based on the details of our payment certificate.
              </p>
              <h3 className="webflowheading3 red">VAT</h3>
              <p className="webflowparagraphe">
                Flowfo sells the digital goods in its own name.
                <br />
                The calculation and collection of VAT are our responsibility.
                <br />
                VAT does not affect the remuneration due to the creator (based on the price excluding tax).
                <br />
                <br />
                <strong>You are an individual creator:</strong> no VAT procedure is required.
                <br />
                <strong>You are a creator with a company:</strong> you sell digital items to Flowfo which we then
                resell. <br />
                Anticipate in the price of your Flows any taxes that you may have to pay in your state or country.
                <br />
                <strong>
                  The VAT invoiced by Flowfo to end customers cannot be refunded to you under any circumstances.
                </strong>
              </p>
              <h3 className="webflowheading3">Retraction</h3>
              <p className="webflowparagraphe">
                As the content of a Flow is available instantly after a transaction,{' '}
                <strong>you expressly waive your right of retraction</strong>.
              </p>
              <h3 className="webflowheading3">Transaction dispute</h3>
              <p className="webflowparagraphe">
                We remind creators that any user can dispute an internet payment several days after the transaction.
                <br /> If this dispute is legitimate (with evidence), we must reimburse the user.
                <br />
                The price excluding tax is then deducted from your remuneration. If your balance becomes negative, we
                can recover these funds from future payments.
                <br />
                In the event of a legitimate dispute, we lock all the files as soon as possible but you accept that the
                buyer has had access to your files during a given period, without compensation.
              </p>
              <h3 className="webflowheading3">Features and content</h3>
              <p className="webflowparagraphe">
                Our{' '}
                <a href="https://flowfo.me/content-policy" target="_blank" className="webflowlink">
                  content policy
                </a>{' '}
                is available in the appendix and is an integral part of the contract that you sign by registering for
                Flowfo.
                <br />
                We proactively review certain pages and posts. We review flagged pages to identify potential violations
                of our guidelines.
                <br />
                We may terminate or suspend your account at any time at our discretion.
                <br />
                We can also cancel any subscription and remove any description, file, news, comment at our discretion.{' '}
                <br />
                These investigations may take a long time to resolve. In most situations, we'll work with the creator to
                resolve the issue.
                <br />
                <br />
                <strong>Flowfo is NOT a file storage or a backup solution. Always keep copies on other media.</strong>
                <br />
                <br />
                We are constantly testing new features with the aim of improving Flowfo.
                <br />
                We can add or remove features.
                <br />
                You agree to use the platform as is and waive any compensation in the event of regression / deletion of
                a feature.
              </p>
              <h3 className="webflowheading3 red">About creations</h3>
              <p className="webflowparagraphe">
                <strong>As the creator, you retain full ownership of the files you post to Flowfo.</strong>
                <br />
                You authorize us to display, classify, sort, sell, store them on the platform.
                <br />
                This authorization is valid for the whole world, as long as you are registered on the site and your work
                is available there. <br />
                You cannot prevent us from presenting your work in a particular geographical area or during certain
                periods.
                <br />
                <br />
                You authorize Flowfo to communicate using your creative material (covers, banners, pages, extracts of
                images...) <br />
                You agree to see your work edited and cropped to correspond to the format constraints of certain media
                (social networks in particular).
                <br />
                The purpose of this license is strictly limited to promoting your art and the platform. We will never
                try to steal or abuse your creations.
                <br />
                <br />
                <strong>
                  As the creator, you allow users to download your creations for offline use.
                  <br />
                  As a user, you agree that the content available on Flowfo is for private use only.
                </strong>
                <br />
                Any commercial use is strictly prohibited (whether the files are available for free or not).
                <br />
              </p>
              <h3 className="webflowheading3 red">Flowfo's creations</h3>
              <p className="webflowparagraphe">
                You can use our name and logo to promote your Flowfo page, but you cannot use them for anything else
                without our permission.
                <br />
                Our creations are protected by copyright, trademark and trade secret laws. <br />
                This includes the text on the site, the logo, its variants, the name of the site and its code.
              </p>
              <h3 className="webflowheading3">Legal proceedings</h3>
              <p className="webflowparagraphe">
                You are jointly and severally liable if we are sued for your use or conduct on Flowfo. <br />
                You will indemnify us for all losses and liabilities, including legal costs, that may arise from such
                prosecution and / or conviction.
                <br />
                If you lose money as a result of using Flowfo, any payment made to you is limited to the amount of money
                we received from your transactions.
                <br />
                In the event of a problem, the competent court is a French court based in Paris.
              </p>
              <h3 className="webflowheading3">Personal data</h3>
              <p className="webflowparagraphe">
                We share your personal data with our payment provider.
                <br />
                In order to make subscriptions possible, our payment provider (PCI standard) keeps the bank details of
                each buyer.
                <br />
                Flowfo does not store this information on its own servers and only has access to a truncated version.
                <br />
                Any user can withdraw their information via the API available on the page
                https://flowfo.me/profile/settings
                <br />
                Any subscriptions will then be stopped at the end of the month.
              </p>
              <h3 className="webflowheading3">About Flowfo</h3>
              <p className="webflowparagraphe">
                Flowfo SAS is located in France - 6, rue des Abbesses - 75018 PARIS. RCS 847 854 676 - Paris
                (01/30/2019) with a capital of € 15,000. Tax number: FR67847854676.
                <br />
                The site is hosted by AWS - Europe.
                <br />
                You can contact us by email at{' '}
                <a href="mailto:contact@flowfo.me?subject=About%20your%20conditions" className="webflowlink">
                  contact@flowfo.me
                </a>
                <br />
              </p>
              <div className="divendblank" />
            </div>
          </div>
          {/*[if lte IE 9]><![endif]*/}

          {/* FIN HTML WEBFLOW terms and conditions english */}
        </div>
      </>
    );
  }
}

export default TermsAndConditions;
